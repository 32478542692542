<template>
  <div id="CartDetail" class="section">
    <div class="columns is-multiline is-vcentered is-mobile">
      <!-- Back Button -->
      <div class="column is-1">
        <div @click.prevent="navigateToCartManage">
          <b-icon class="clickable-icon" icon="chevron-left" size="is-medium" />
        </div>
      </div>
      <!-- Title -->
      <div
        :class="
          cartObject.status === 'paid'
            ? 'column is-7-desktop is-7-tablet is-12-mobile'
            : 'column is-9-desktop is-9-tablet is-12-mobile'
        "
      >
        <h1 class="title">CART DETAILS</h1>
      </div>
      <!-- Completed Button -->
      <div
        v-if="cartObject.status === 'paid'"
        class="column is-2 is-hidden-mobile"
      >
        <b-button
          expanded
          type="is-primary"
          icon-left="file-check"
          @click.prevent="presentConfirmCompletedCart"
        >
          Completed
        </b-button>
      </div>
      <!-- Remove Button -->
      <div class="column is-2 is-hidden-mobile">
        <b-button
          expanded
          outlined
          type="is-danger"
          icon-left="close"
          @click.prevent="presentConfirmRemoveCart"
        >
          Remove
        </b-button>
      </div>
    </div>
    <div v-if="cartObject" class="container">
      <div style="margin-top: 1.5rem;">
        <div class="columns is-multiline is-mobile">
          <div class="column is-12">
            <h1 class="title is-4">
              Order ID: {{ cartObject.order_id }}
              <b-tag v-if="cartObject.status === 'paid'" type="is-warning"
                >PAID</b-tag
              >
              <b-tag v-else-if="cartObject.status === 'done'" type="is-success"
                >CLOSE</b-tag
              >
              <b-tag v-else-if="cartObject.status === 'open'" type="is-info"
                >OPEN</b-tag
              >
              <b-tag
                v-else-if="cartObject.status === 'need_payment'"
                type="is-danger"
                >UNPAID</b-tag
              >
              <b-tag v-else type="is-danger">{{ cartObject.status }}</b-tag>
            </h1>
          </div>
          <div class="column is-5-desktop is-12-tablet is-12-mobile">
            <h1 class="title is-4">Product List</h1>

            <div v-for="(cartItem, index) in cartObject.products" :key="index">
              <div class="columns is-vcentered">
                <div class="column">
                  <div class="columns is-centered is-multiline">
                    <div class="column is-4">
                      <img :src="cartItem.main_image" />
                    </div>
                    <div class="column is-8">
                      <p class="title is-5">
                        <b>{{ cartItem.name.en }}</b>
                      </p>
                      <div class="column is-12" style="padding: 0;">
                        <div class="columns is-multiline is-mobile">
                          <div class="column is-6">
                            Price:
                          </div>
                          <div class="column is-6 has-text-right">
                            {{ cartItem.price | thousandseparators }} THB
                          </div>
                          <div class="column is-6">
                            Quantity:
                          </div>
                          <div class="column is-6 has-text-right">
                            {{ cartItem.quantity }} item(s)
                          </div>
                          <div class="column is-6">
                            Product Total:
                          </div>
                          <div class="column is-6 has-text-right">
                            {{
                              (cartItem.price * cartItem.quantity)
                                | thousandseparators
                            }}
                            THB
                          </div>
                          <div class="column is-6">
                            Shipping Total:
                          </div>
                          <div class="column is-6 has-text-right">
                            <div v-if="cartItem.shipping">
                              {{
                                (cartItem.shipping * cartItem.quantity)
                                  | thousandseparators
                              }}
                              THB
                            </div>
                            <div v-else>-</div>
                          </div>
                          <div class="column is-6">
                            <b>Total:</b>
                          </div>
                          <div class="column is-6 has-text-right">
                            <b>
                              <div v-if="cartItem.shipping">
                                {{
                                  ((cartItem.price + cartItem.shipping) *
                                    cartItem.quantity)
                                    | thousandseparators
                                }}
                                THB
                              </div>
                              <div v-else>
                                {{
                                  (cartItem.price * cartItem.quantity)
                                    | thousandseparators
                                }}
                                THB
                              </div>
                            </b>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
            </div>
          </div>
          <div
            class="column is-6-desktop is-12-tablet is-12-mobile is-offset-1-desktop"
          >
            <h1 class="title is-4">Shipping Information</h1>

            <div class="column is-12" style="padding: 0;">
              <div class="columns is-multiline is-mobile">
                <div class="column is-12">
                  <p>Reciever: {{ fullname }}</p>
                </div>
                <div class="column is-12">
                  <p>Email: {{ email }}</p>
                </div>
                <div class="column is-12">
                  <p>Phone: {{ phone }}</p>
                </div>
                <div class="column is-12">
                  <p>
                    Ship Address:
                    {{ formatted_address }}
                  </p>
                </div>
                <div class="column is-6">
                  <p>
                    Shipping Zone: {{ cartObject.zone ? cartObject.zone : "-" }}
                  </p>
                </div>
              </div>
            </div>
            <hr />
            <!-- PAYMENT TYPE -->
            <h1 class="title is-4">
              Payment Information
              <b-tag
                v-if="
                  cartObject.payment_type &&
                    cartObject.payment_type === 'omise_card'
                "
                type="is-info"
                >Omise Card</b-tag
              >
              <b-tag v-else type="is-danger">
                -
              </b-tag>
            </h1>
            <div
              v-if="cartObject.price"
              class="column is-12"
              style="padding: 0;"
            >
              <div class="columns is-multiline is-mobile">
                <div class="column is-6">
                  <p>Payment Date:</p>
                </div>
                <div class="column is-5 has-text-right">
                  <p>
                    {{
                      cartObject.payment_time
                        ? cartObject.payment_time
                        : "-" | dateformatter
                    }}
                  </p>
                </div>
                <div class="column is-6">
                  <p>Product Price:</p>
                </div>
                <div class="column is-5 has-text-right">
                  <p>
                    {{
                      cartObject.price.product
                        ? cartObject.price.product
                        : "-" | thousandseparators
                    }}
                    THB
                  </p>
                </div>
                <div class="column is-6">
                  <p>Shipping Price:</p>
                </div>
                <div class="column is-5 has-text-right">
                  <p>
                    {{
                      cartObject.price.shipping
                        ? cartObject.price.shipping
                        : "-" | thousandseparators
                    }}
                    THB
                  </p>
                </div>
                <div class="column is-6">
                  <p><b>Total Price:</b></p>
                </div>
                <div class="column is-5 has-text-right">
                  <p>
                    <b>
                      {{
                        cartObject.price.total_price
                          ? cartObject.price.total_price
                          : "-" | thousandseparators
                      }}
                      THB
                    </b>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Mobile: Remove Button -->
      <div class="is-hidden-tablet" style="margin-top: 1rem;">
        <b-button
          expanded
          outlined
          type="is-danger"
          icon-left="close"
          @click.prevent="presentConfirmRemoveCart"
        >
          Remove
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import CartApi from "@/apis/CartApi";
import Helpers from "@/utils/Helpers";

export default {
  name: "CartDetail",
  props: ["cart"],
  data() {
    return {
      fullname: "",
      email: "",
      phone: "",
      formatted_address: "",
      cartObject: this.cart
    };
  },
  filters: {
    dateformatter: function(value) {
      if (!value) return "";
      return Helpers.dateFormatterWithTime(value);
    },
    thousandseparators: function(value) {
      return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    }
  },
  methods: {
    generateFormattedAddress: function(addressObj) {
      let name = [];
      let address = [];

      // Looping through arrays created from Object.keys
      const entries = Object.entries(addressObj);
      for (const [key, value] of entries) {
        switch (key) {
          case "email":
            this.email = value;
            break;

          case "contact_number":
            this.phone = value;
            break;

          case "first_name":
          case "last_name":
            name.push(value);
            break;

          default:
            address.push(value);
            break;
        }
      }
      this.fullname = name.join(" ");
      this.formatted_address = address.join(", ");
    },
    getCartDetail: async function(cart_id) {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      });
      const response = await CartApi.requestCartById(cart_id);
      loadingComponent.close();
      this.cartObject = response;

      this.generateFormattedAddress(response.contact);
    },
    completedCartbyId: async function(cart_id) {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      });
      const response = await CartApi.completedCartById(cart_id);
      loadingComponent.close();
      if (response) {
        this.navigateToCartManage();
      }
    },
    removeCartbyId: async function(cart_id) {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      });
      const response = await CartApi.removeCartById(cart_id);
      loadingComponent.close();
      if (response) {
        this.navigateToCartManage();
      }
    },
    navigateToCartManage: function() {
      this.$router.push({ name: "cart" });
    },
    presentConfirmCompletedCart: function() {
      if (this.cartObject._id) {
        this.$buefy.dialog.confirm({
          title: "Confirm Completed Cart",
          message:
            "Are you sure you want to completed cart <b>" +
            this.cartObject.order_id +
            "</b>?",
          confirmText: "Confirm",
          type: "is-success",
          hasIcon: true,
          onConfirm: () => this.completedCartbyId(this.cartObject._id)
        });
      }
    },
    presentConfirmRemoveCart: function() {
      if (this.cartObject._id) {
        this.$buefy.dialog.confirm({
          title: "Confirm Remove Cart",
          message:
            "Are you sure you want to permanently remove cart <b>" +
            this.cartObject.order_id +
            "</b>?",
          confirmText: "Confirm",
          type: "is-danger",
          hasIcon: true,
          onConfirm: () => this.removeCartbyId(this.cartObject._id)
        });
      }
    }
  },
  mounted() {
    if (this.cartObject) {
      const cart_id = this.cartObject._id;
      this.getCartDetail(cart_id);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#CartDetail {
  text-align: left;
}
.clickable-icon {
  cursor: pointer;
}
</style>
