import MainConnection from "./MainConnection";
import AlertManager from "../utils/AlertManager";

export default {
  requestCartList: async function(option) {
    try {
      let requestURL = "";
      switch (option) {
        case "all":
          requestURL = "/carts";
          break;

        default:
          requestURL = "/carts?status=" + option;
          break;
      }
      const response = await MainConnection.request().get(requestURL);

      switch (response.data.code) {
        case "0":
          return response.data.data;

        default:
          AlertManager.showErrorDialog(
            response.data.code,
            response.data.message
          );
          break;
      }
    } catch (error) {
      MainConnection.errorMessage(error);
    }
  },
  requestCartById: async function(cart_id) {
    try {
      const requestURL = "/carts/" + cart_id;
      const response = await MainConnection.request().get(requestURL);

      switch (response.data.code) {
        case "0":
          return response.data.data;

        default:
          AlertManager.showErrorDialog(
            response.data.code,
            response.data.message
          );
          break;
      }
    } catch (error) {
      MainConnection.errorMessage(error);
    }
  },
  completedCartById: async function(cart_id) {
    try {
      const requestURL = "/carts/" + cart_id + "/status";
      const response = await MainConnection.request().patch(requestURL);

      switch (response.data.code) {
        case "0":
          return true;

        default:
          AlertManager.showErrorDialog(
            response.data.code,
            response.data.message
          );
          return false;
      }
    } catch (error) {
      MainConnection.errorMessage(error);
    }
  },
  removeCartById: async function(cart_id) {
    try {
      const requestURL = "/carts/" + cart_id;
      const response = await MainConnection.request().delete(requestURL);

      switch (response.data.code) {
        case "0":
          return true;

        default:
          AlertManager.showErrorDialog(
            response.data.code,
            response.data.message
          );
          return false;
      }
    } catch (error) {
      MainConnection.errorMessage(error);
    }
  }
};
